import MeshGroupComponent from '@wix/thunderbolt-elements/src/thunderbolt-core-components/MeshGroup/viewer/MeshGroup';


const MeshGroup = {
  component: MeshGroupComponent
};


export const components = {
  ['MeshGroup']: MeshGroup
};

